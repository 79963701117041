<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row gy-3">
            <div class="col-md-4 ">
                <label class="form-label" for="firstName">First Name <span class="text-danger">*</span></label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
                <div v-for="(item, index) in v$.data.first_name.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-4">
                <label class="form-label" for="middleName">Middle Name</label>
                <input id="middleName" type="text" class="form-control" v-model="data.middle_name"
                placeholder="Middle name" name="middle_name" />
            </div>
            <div class="col-md-4">
                <label class="form-label" for="LastName">Last Name <span class="text-danger">*</span></label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
                <div v-for="(item, index) in v$.data.last_name.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <label class="form-label" for="FullName">Full Name</label>
                <input id="FullName" type="text" class="form-control" v-model="data.full_name"
                placeholder="Full name" name="full_name" />
            </div>
            <div class="col-sm-6">
                <label class="form-label" for="inputMobile">Phone Number <span class="text-danger">*</span></label>
                <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
                    :class="{ 'is-invalid': v$.data.mobile.$error  }" placeholder="mobile" name="mobile" />
                <div v-for="(item, index) in v$.data.mobile.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>  
            </div>
            <div class="col-sm-6">
                <label class="form-label" for="emailAddress">Email Address</label>
                <input id="emailAddress" type="email" class="form-control" v-model="data.email"
                placeholder="Email Address" name="email" />
            </div>
            <div class="col-sm-6">
                <label for="gender" class="form-label">Gender <span class="text-danger">*</span></label>
                <select v-model="data.gender" name="gender" id="gender" class="form-select"
                :class="{ 'is-invalid': v$.data.gender.$error }" >
                <option disabled :value="null" >--select gender--</option>
                <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                    :value="gender.value">{{gender.label}}</option>
                </select>
            </div>
            <div class="col-md-5">
                <label class="form-label" for="nin_number"> National Identification Number (NIN) <span class="text-danger">*</span></label>
                <input v-model="data.nin" id="nin_number" class="form-control" type="number"
                placeholder="NIN number" name="nin" :class="{ 'is-invalid': v$.data.nin.$error }">
                <div v-for="(item, index) in v$.data.nin.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div> 
            </div>
            <div class="col-md-7">
                <label class="form-label" for="vin_number">Voter Identification Number (VIN) <span class="text-danger">*</span></label>
                <input v-model="data.vin" id="vin_number" class="form-control" type="text"
                placeholder="VIN" name="vin" >
            </div>
            <div class="col-12">
                <label class="form-label" for="account_name">Account name <span class="text-danger">*</span></label>
                <input id="account_name" type="text" class="form-control"
                    v-model="data.account_name" placeholder="Account name" name="account_name" />
            </div>
            <div class="col-sm-5">
                <label class="form-label" for="bank_id">Bank name <span class="text-danger">*</span></label>
                <select v-model="data.bank_id"
                    name="bank_id" id="bank_id" class="form-select"
                :class="{ 'is-invalid': v$.data.bank_id.$error }">
                    <option disabled value="" >--select bank--</option>
                    <option v-for="(bank, bIndex) in bankOptions" :key="bIndex" 
                    :value="bank.id">{{bank.name}}</option>
                </select>
                <div v-for="(item, index) in v$.data.bank_id.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-sm-7">
                <label class="form-label" for="account_number">Account number <span class="text-danger">*</span></label>
                <input id="account_number" type="text" class="form-control"
                    v-model="data.account_number"
                placeholder="Account number" name="account_number"
                :class="{ 'is-invalid': v$.data.account_number.$error }" />
                <div v-for="(item, index) in v$.data.account_number.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
           
            <div class="col-md-7 col-lg-8">
                <label for="address" class="form-label"> Home Address <span class="text-danger">*</span></label>
                <input v-model="data.address" id="address" name="address"
                class="form-control" type="text" placeholder="Business Address">
            </div>
            <div class="col-md-5 col-lg-4">
                <label for="local_government_id" class="form-label">Local Government Area <span class="text-danger">*</span></label>
                <select @change="lgaChange()" v-model="data.local_government_id" 
                name="local_government_id" id="local_government_id" class="form-select">
                    <option disabled value="" >--choose LGA--</option>
                    <option v-for="(lga, lgIndex) in lgaOptions" :key="lgIndex" 
                    :value="lga.id">{{lga.name}}</option>
                </select>
            </div>
            <div class="col-sm-6">
                <label for="registration_area_id" class="form-label">Ward <span class="text-danger">*</span></label>
                <select @change="wardChange()" v-model="data.registration_area_id"
                 name="registration_area_id" id="registration_area_id" class="form-select" >
                    <option disabled value="" >--choose ward--</option>
                    <option v-for="(ward, wIndex) in wardOptions" :key="wIndex" 
                    :value="ward.id">{{ward.name}}</option>
                </select>
            </div>
            <div class="col-sm-6">
                <div class="">
                    <label>Polling Unit </label>
                    <multiselect 
                        mode="single"
                        searchable
                        valueProp="id"
                        trackBy="name" label="name"
                        :options="unitOptions" 
                        v-model="data.polling_unit_id" placeholder="--select--"></multiselect>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, minLength, requiredIf, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import Multiselect from '@vueform/multiselect'

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        IsLoading,
        Multiselect,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        programId: {
            type: Number,
            required: true
        }
    },
    data(){
        return{
            isLoading: true,
            data: {
                program_id: this.programId,
                first_name: "",
                middle_name: "",
                last_name: "",
                full_name: "",
                gender: "",
                email: "",
                mobile: "",

                nin: "",
                vin: "",

                bank_id: "",
                account_name: "",
                account_number: "",

                address: "",
                local_government_id: "",
                registration_area_id: "",
                polling_unit_id:"",
            },
            genderOptions: [
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Others', value: 'Others' }
            ],
            wardOptions: [],
            unitOptions: [],
            bankOptions: [],
        }
    },
    validations() {
        return {        
            data:{
                first_name: {
                    required: helpers.withMessage("First name is required", required),
                },
                last_name: {
                    required: helpers.withMessage("Last name is required", required),
                },
                mobile: {
                    required: helpers.withMessage("Phone number is required", required),
                },
                gender: {
                    // required: helpers.withMessage("Select gender", required),
                }, 
                nin: {
                    required: helpers.withMessage("NIN is required", required),
                },
                bank_id: {
                    required: helpers.withMessage("Select a bank", required),
                },
                account_number: {
                    required: helpers.withMessage("Account number is required", required),
                },            
            }
        }
    },
    computed:{
        lgaOptions(){
            return this.$store.state.localGovernments
        },
        years() {
            let factor = 0;
            let startYear = new Date().getFullYear() - factor;
            return Array.from({ length: 100 }, (_, index) => {
                const yearValue = startYear - index;
                return {
                    value: yearValue,
                    label: yearValue.toString()
                };
            });
        },
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/beneficiaries/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit('addItem', response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/beneficiaries/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.wardOptions = [];
            this.unitOptions = [];
            this.$emit("closeMe")
        },
        lgaChange(clear = true){
            if(clear) { this.data.registration_area_id = this.data.polling_unit_id  = ""; }
            this.wardOptions = this.unitOptions = [];
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.wardOptions = response.data.data
                }
            })
        },
        wardChange(clear = true){
            if(clear) { this.data.polling_unit_id = ""; }
            this.unitOptions = [];
            if(!this.data.local_government_id || !this.data.registration_area_id){
                return
            }
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/polling-units?local_government_id=${this.data.local_government_id}&registration_area_id=${this.data.registration_area_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.unitOptions = response.data.data
                }
            })
        },
        getOptionData(){
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/banks`)
                .then((response) => {
                if(response.data.success){
                    this.bankOptions = response.data.data
                }
            })
            this.$store.dispatch('localGovernments')
            .then(response => this.isLoading = false );
        },
    },
    mounted(){
        this.getOptionData()
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            if(this.editItem.unit){  this.data.registration_area_id = this.editItem.unit.registration_area_id }
            if(this.editItem.ward){  this.data.local_government_id = this.editItem.ward.local_government_id }
            this.lgaChange(false);
            this.wardChange(false)
        }
    }
}
</script>